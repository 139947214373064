import React from "react";
// import { CartContext } from "../context/cart-context";
// import { isInCart } from '../helpers';
import { Card, CardMedia, Typography, Grid, CardActionArea, CardHeader } from "@mui/material";
import { useNavigate} from "react-router-dom";
// import { grey } from "@mui/material/colors";

function ProductGallery({payload}) {

    // const { id, name, title, imageUrl, variations } = payload
    const { id, name, title, imageUrl, variations } = payload

    const navigate = useNavigate()

    return(
        <Grid item xs={12} sm={6} md={4} key={id} sx={{fontFamily: 'inherit'}}>
            <Card variant='outlined' sx={{
                }}>
                <CardActionArea sx={{fontFamily: 'inherit'}}>
                <CardMedia
                    component={'img'}
                    sx={{
                        borderRadius: 0,
                        height: '100%',
                        width: '100%', 
                        }}
                    image={imageUrl[0]}
                    title={title}
                    // onClick={() => {navigate(`/product/${id}`)}}
                    onClick={() => {navigate(`/product/${name}`)}}
                    />
                <CardHeader 
                    disableTypography 
                    title={<Typography textAlign={'center'} sx={{fontSize: 18, fontFamily:'Quicksand', fontWeight: 600}}>{title}-{variations[0].type}</Typography>}
                    subheader={<Typography textAlign={'center'} sx={{fontSize: 16, fontFamily:'Quicksand', fontWeight: 400}}>From ${variations[0].price}</Typography>}/>
                </CardActionArea>

            </Card>
        </Grid>

    )
}

export default ProductGallery