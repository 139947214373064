import React , {
    useState,
    useContext,
    useEffect
} from "react";
import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
import { ProductsContext } from "../context/products-context";
import { 
    // Typography, 
    Box, 
    Container, 
    // Card,
    // CardHeader,
    // CardActionArea,
    // CardMedia,
    Grid 
} from "@mui/material";
// import { grey, yellow } from "@mui/material/colors";
import ProductGallery from "../components/productGallery";
// import { useNavigate} from "react-router-dom";
// import Fake from "../components/fake";
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from "react-helmet";


const Shop = () => {

    const { products } = useContext(ProductsContext)
    const theme = useTheme()

    const [loading, setLoading] = useState(true)

    useEffect( () => {

        if (products) {
            setLoading(false)
        }

        return( () => {})
    }, [products])


    return(
        <div>
            <Helmet>
                <title>The Pantry Shop</title>
                <meta name="description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
                <meta name="keywords" content="pantry, pantry bakery, pantry catering, event catering, plant-based catering, organic catering, authentic korean food catering, low fat lunch, low sodium lunch box, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch catering, executive breakfast, corporate event catering, fresh, special event lunch box" />

                <meta property="og:title" content={'The Pantry Shop'} />
                <meta property="og:description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering/shop'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'The Pantry Shop'}  />
                <meta name="twitter:description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
            </Helmet>
            <Layout>

                <Container maxWidth='lg'>

                    {
                        loading ? 

                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '85vh'}}>
                            <CircularProgress size={'4rem'}/>
                        </Box>
                        
                        :

                        <Box>

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                mt: '2%',
                                }}>

                                <Grid 
                                    container 
                                    justifyContent="center"
                                    alignItems="center"
                                    rowSpacing={2} 
                                    columnSpacing={2}>

                                        {
                                            products.map(
                                                (product, index) => <ProductGallery key={index} payload={product}/>
                                            )
                                        }

                                </Grid>

                            </Box>

                        </Box>

                    }

                </Container>

            </Layout>
        </div>
    )
}

export default Shop