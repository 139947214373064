import React from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Card, Typography, CardMedia, IconButton } from "@mui/material";
// import CheckOut from "../stripe/checkoutStripe";

const CartItem = (props) => {

    const { product, Price, Var, subid, quantity, increase, decrease, removeProduct } = props

    return(
        <Box sx={{display: 'flex', justifyContent: 'center'}}> 

            <Card variant={'outlined'} sx={{
                display: 'flex', 
                justifyContent: 'space-evenly', 
                alignContent: 'center', 
                mb: '2%',
                borderRadius: 0,
                }}>

                <CardMedia
                    component={'img'}
                    sx={{width: '30%',}}
                    image={product.imageUrl[0]}
                    title={product.title}/>

                <Box sx={{
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    // mb: '2%'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography textAlign={'left'} sx={{ml: '5%', fontSize: 18, fontFamily: 'Quicksand', fontWeight: 700}}>
                                {product.title}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center'}}>
                            <Typography textAlign={'left'} sx={{ml: '5%', fontSize: 16, fontFamily: 'Quicksand', fontWeight: 500}}>
                                {Var.type}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography textAlign={'left'} sx={{ml: '5%', fontSize: 16, fontFamily: 'Quicksand', fontWeight: 500}}>
                                Price: ${Price}
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                            }}>
                                { 
                                    quantity === 1 &&
                                    <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center', width: '20%'}}>
                                        <IconButton onClick={
                                            () => removeProduct(subid)
                                            }>
                                            <DeleteOutlineIcon/>
                                        </IconButton>        
                                    </Box>
                                } {
                                    quantity > 1 &&
                                    <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'center', width: '20%'}}>
                                        <IconButton onClick={() => decrease({product, Price, Var, subid, quantity})}>
                                            <RemoveIcon/>
                                        </IconButton>
                                    </Box>
                                }

                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: {xs: '45%', sm: '35%', height: '100%'}}}>
                                    <Typography textAlign={'center'} sx={{fontSize: 16, fontFamily: 'Quicksand', fontWeight: 500}}> 
                                        Quantity: {quantity}
                                    </Typography> 
                                </Box>

                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '16%', height: '100%'}}>
                                    <IconButton onClick={() => increase({product, Price, Var, subid, quantity})}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>

                                <Box sx={{width: '23%'}}>

                                </Box>

                        </Box>                                

                </Box>

            </Card>
        </Box>
    )
}

export default CartItem