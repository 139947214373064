import React from "react";
import { Button } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { styled } from '@mui/material/styles';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(yellow[600]),
    lineHeight: 2,
    backgroundColor: 'white',
    borderColor: yellow[600],
    border: '1.5px solid', 
    borderRadius: '2rem',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Quicksand',
    '&:hover': {
      backgroundColor: '#F8F6F1',
      // color: theme.palette.getContrastText(green[400]),
      lineHeight: 2,
      fontWeight: 500,
    },
  }));

export default ColorButton