import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Container } from "@mui/material";

const Layout = ({children}) => {

    return(
        <>
        <Container 
            // maxWidth
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                // background: '#F8F6F1'
                background: '#FFFFFF'
            }}
            >
            <Header />
                {
                    children
                }
            <Footer />
        </Container>
        </>
    )
}

export default Layout