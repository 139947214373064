import {Routes, Route} from 'react-router-dom'
import Homepage from './pages/home';
import NotFound from './components/not-found';
import Shop from './pages/shop';
// import SingleProduct from './components/single-product';
import CartPage from './pages/cart-page';
import Checkout from './components/checkout';
import Success from './components/success';
// import Canceled from './components/canceled';
// import Signup from './pages/Signup';
// import Signin from './pages/Signin';
import Contactpage from './pages/contact';
// import Servicespage from './pages/services';
import './App.css';
// import SFPro from './fonts/SF-Pro/SFPro.ttf';
// import Reservation from './pages/reservations';
// import Menus from './pages/menus';
// import Weddings from './pages/weddings';
// import Corporate from './pages/corporate';
// import NewProduct from './components/newProduct';
import RadicalProduct from './components/radicalProduct';
import Inside from './pages/insideBox';

import { 
    createTheme, 
    ThemeProvider, 
    // responsiveFontSizes, 
    // useTheme 
} from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';
import { Helmet } from 'react-helmet';


// let theme = createTheme();

// theme = createTheme({
//     typography: {
//       fontFamily: [
//         'Goudy Bookletter 1911',
//         'serif'
//       ].join(','),
//     },
    // palette: {
    //   primary: grey[400],
    // },
//   });

// theme = responsiveFontSizes(theme, {breakpoints: ['xs', 'sm', 'md', 'lg'], factor: 3});

const theme = createTheme({
    palette: {
        primary: {
        //   light: '#757ce8',
            // main: '#3f50b5',
            main: '#F8F6F1'
            // main: '#FFFFFF',
        //   dark: '#002884',
        //   contrastText: '#fff',
        },
        secondary: {
        //   light: '#ff7961',
            // main: '#f44336',
            main: grey[800],
        //   dark: '#ba000d',
        //   contrastText: '#000',
        },
        info: {
            // main: yellow['600'],
            main: grey[900],
        },
        warning: {
            main: yellow['600'],
        },
        action: {
            main: grey[800],
        }
    },
})

// theme.typography.h3 = {
//     fontSize: '1.2rem',
//     '@media (min-width:600px)': {
//       fontSize: '1.5rem',
//     },
//     [theme.breakpoints.up('md')]: {
//       fontSize: '2rem',
//     },
//   };

function App() {
    
    return (
        <ThemeProvider theme={theme}>
        <div className="App">                
            <Helmet>
                    {/* <title>Welcome to The Pantry Bakery & Catering</title> */}
                    <meta 
                        name="description" 
                        content={'The pantry bakery and catering provides unique cateringa nd culinary experience centered around locally sourced, fresh, and organic ingredients in Los Angeles, Irvine counties in California.'} />
                    <meta 
                        name="keywords" 
                        content="pantry, pantry bakery, pantry catering, event catering, plant-based catering, organic catering, authentic korean food catering, low fat lunch, low sodium lunch box, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch catering, executive breakfast, corporate event catering, fresh, special event lunch box" />

                    <meta property="og:title" content={'Welcome to The Pantry Bakery and Catering'} />
                    <meta property="og:description" content={'The pantry bakery and catering provides unique cateringa nd culinary experience centered around locally sourced, fresh, and organic ingredients in Los Angeles, Irvine counties in California.'} />
                    <meta property="og:url" content={'https://thepantry.catering'} />
                    <meta property="og:type" content="website" />
                </Helmet>
            <Routes>

                <Route exact path='/' Component={Homepage} />
                <Route path='/shop' Component={Shop} />
                {/* <Route path='/reservation' Component={Reservation} /> */}
                {/* <Route path='/menus' Component={Menus} /> */}
                {/* <Route path='/product/:id' Component={SingleProduct} /> */}
                {/* <Route path='/product/:id' Component={NewProduct} /> */}
                <Route path='/product/:id' Component={RadicalProduct} />
                {/* <Route path='/product/:id' Component={RadicalProduct} /> */}

                <Route path='/insidemybox' Component={Inside} />

                <Route path='/cart' Component={CartPage} />
                {/* <Route path='/weddings' Component={Weddings} />
                <Route path='/corporate' Component={Corporate} /> */}
                <Route path='/contact' Component={Contactpage} />
                {/* <Route path='/services' Component={Servicespage} /> */}
                <Route path='/checkout' Component={Checkout} />
                <Route path='/success' Component={Success} />
                {/* <Route path='/canceled' Component={Canceled} />
                <Route path='/signup' Component={Signup} />
                <Route path='/signin' Component={Signin} /> */}
                <Route path='*' Component={NotFound} />
            </Routes>
        </div>
        </ThemeProvider>
    );
}

export default App;
