import React from "react";
import { Box, Typography } from "@mui/material";

function PageHeader(props) {
    return(
        <Box sx={{
            display: 'flex', 
            alignItems: 'center', 
            width: '100%',
            justifyContent: 'center'}}>
            <Typography 
                textAlign={'center'} 
                paragraph
                sx={{
                    fontSize: 48, 
                    fontWeight: 700,
                    fontFamily: 'Neuton',
                    pt: '4%',
                    // pb: '2%',
                    // width: '100%', 
                    // height: '100%'
                    }}>
                    {props.title}
            </Typography>
        </Box>
    )
}

export default PageHeader