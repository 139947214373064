
const stripe = require('stripe')(process.env.REACT_APP_SECRET_KEY);

async function CheckOut(props) {

    let li = []

    props.map( (item) => {

        return li.push({
            price_data: {
                currency: "usd",
                product_data: {
                    name: item.product.title.concat('-', item.Var.type),
                    description: item.product.description,
                    images: [
                        item.product.imageUrl[1],
                    ],
                    metadata: [],
                },
                unit_amount: Math.round( 100 * item.Price ),
            },
            quantity: item.quantity,
        })
    })

    const payload = {
        line_items: li,
        mode: 'payment',
        success_url: 'http://thepantry.catering/success?session_id={CHECKOUT_SESSION_ID}',
    }

    const session = await stripe.checkout.sessions.create(
        payload
    )

    return {id: session.id}


}

export default CheckOut