import React, { useContext, useState, useEffect } from "react";
import { ProductsContext } from "../context/products-context";
import Layout from "./layout";
import {
    useNavigate,
    useParams,
  } from "react-router-dom";
import { Typography, Box, Container, Stack } from "@mui/material";
import { CartContext } from "../context/cart-context";
import ColorButton from "./coloredButton";
import CircularProgress from '@mui/material/CircularProgress';
// import banner from '../assets/banner.jpg';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import milkIcon from '../assets/milk-10.svg';
// import soy from '../assets/soy-sauce-soya.svg';
// import nut from '../assets/peanut-11.svg';
import { Carousel } from "react-responsive-carousel";
// import { grey } from "@mui/material/colors";
import { isInCart } from "../helpers";
// import IM1 from '../assets/aa.jpg';
// import IM2 from '../assets/aa2.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Helmet } from 'react-helmet';

export default function RadicalProduct({match}) {

    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const {products} = useContext(ProductsContext)
    const { addProduct, cartItems } = useContext(CartContext)
    const navigate = useNavigate()
    const [product, setProduct] = useState(null)
    const [itemsInCart, setItems] = useState(null)
    const [subId, setSubId] = useState('')
    const [name, setName] = useState('')

    useEffect(() =>{

        // console.log(id)

        // const product = products.find(item => Number(item.id) === Number(id))
        const product = products.find(item => item.name === id)

        if (product) {

            // console.log(product.variations[0].image)

            setItems(isInCart(product, cartItems))

            setProduct(product)

            setSubId(product.id)

            // setName(product.name)
            setName(product.title + ' ' + product.subTitle)

            setLoading(false)

        } else {

            return navigate('/shop')
        }

        return( 
            ()=>{}
            )

    }, [navigate, products, cartItems, id, itemsInCart])

    return(
        <div>
            <Helmet>
                <title>{name} Details</title>
                <meta name="description" content={'The pantry catering item details'} />
                <meta name="keywords" content="pantry, pantry bakery, pantry catering, event catering, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch, executive breakfast, corporate event, fresh, lunch box" />

                <meta property="og:title" content={`${name} details`} />
                <meta property="og:description" content={'The pantry catering item details'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={`https://thepantry.catering/product/${id}`} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={`${id} details`}  />
                <meta name="twitter:description" content={'The pantry catering item details'} />
            </Helmet>

            <Layout>
                <Container maxWidth='md'>
                    {
                        loading ? 

                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '85vh'}}>
                            <CircularProgress size={'4rem'}/>
                        </Box>
                        :

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%'}}>

                            <Box sx={{
                                width: '100%', 
                                mt: 2,
                                mb: 1,
                                }}>
                                <Typography textAlign={'center'} sx={{fontSize: 48, fontFamily: 'Quicksand', fontWeight: 600}}>
                                    {product.title}
                                </Typography>

                            </Box>

                            { product.subTitle !== null ? 
                                <Box sx={{
                                    width: '100%', 
                                    mb: 1,
                                    }}>
                                    <Typography textAlign={'center'} sx={{fontSize: 24, fontFamily: 'Quicksand', fontWeight: 500}}>
                                        {product.subTitle}
                                    </Typography>
                                </Box>
                                :
                                <Box sx={{
                                    width: '100%', 
                                    }}>
                                </Box>
                            }
                            
                            {/* Carousel */}
                            <Box sx={{
                                display: 'flex', 
                                // flexDirection: 'row',
                                alignContent: 'center', 
                                // justifyContent: 'center',
                                width: '95%', 
                                // borderRadius: '1rem',
                                // background: 'cyan',
                                mt: {xs: '2%', md: '1%'},
                                }}>
                                <Carousel 
                                    showArrows={true}
                                    showStatus={false}
                                    autoPlay={false}
                                    // infiniteLoop={true}
                                    showIndicators={true} 
                                    showThumbs={true}
                                    >

                                    {
                                        product.variations[0].image.map( (img, index) => 
                                            <div key={index}>
                                                <img 
                                                    src={img} 
                                                    width={'50%'} 
                                                    style={{borderRadius: '0.5rem'}}
                                                    alt="View from top"/>
                                            </div>
                                        )
                                    }

                                </Carousel>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                // alignItems: 'center',
                                // justifyContent: 'center', 
                                width: '95%',
                                mt: 2,
                                }}>
                                <Typography textAlign={'left'} sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 600,}}>
                                    What is indide the box?
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center', 
                                width: {xs: '95%', md: '95%'},
                                mt: 2,
                                }}>
                                <Typography textAlign={'justify'} sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 300,}}>
                                    {product.description}
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                // alignItems: 'center',
                                // justifyContent: 'center', 
                                width: '95%',
                                mt: 2,
                                }}>
                                <Typography textAlign={'left'} sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 600,}}>
                                    What we took out of pantry:
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                // alignItems: 'center',
                                // justifyContent: 'center', 
                                width: '95%',
                                mt: 2,
                                }}>
                                <Typography textAlign={'justify'} sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 300,}}>
                                    {product.ingrediants}
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                // alignItems: 'center',
                                // justifyContent: 'center', 
                                width: '95%',
                                mt: 2,
                                }}>
                                <Typography textAlign={'left'} sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 600,}}>
                                    Allergants
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                // flexDirection: 'row',
                                // alignItems: 'center',
                                // justifyContent: 'center', 
                                width: '95%',
                                mt: 2,
                                }}>
                                    <Stack direction={'row'} spacing={4}>
                                    {product.allergens.map(alg => {return(
                                        <Box key={alg} sx={{
                                            display: 'flex', 
                                            alignContent: 'stretch', 
                                            justifyContent: 'stretch', 
                                            width: '100%', 
                                            background: '#F8F6F1', 
                                            border: 1, 
                                            borderColor: 'primary', 
                                            borderRadius: '0.5rem'}}>
                                        <Typography noWrap sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 500, p: 1}}>
                                            {alg}
                                        </Typography>
                                        </Box>
    )
                                    })}
                                    </Stack>

                                {/* <img src={milkIcon} alt="milk icon" style={{width: '10%', height: '10%'}}/>
                                <img src={soy} alt="milk icon" style={{width: '8%', height: '8%'}}/>
                                <img src={nut} alt="milk icon" style={{width: '8%', height: '8%'}}/> */}
                            </Box>

                            <Box sx={{display: 'flex', width: '95%', mt: 2, mb: 2}}>

                                <Box sx={{ 
                                    width: '50%', 
                                    display: 'flex', 
                                    justifyContent: 'left',
                                    alignItems: 'center',
                                    mt: 2,
                                    }}>

                                    <LocalShippingOutlinedIcon sx={{mr: '2%'}}/>

                                    <Typography sx={{fontSize: {xs: 14, md: 12}}}>
                                        Available for delivery
                                    </Typography>

                                </Box>

                                <Box sx={{
                                    width: '50%', 
                                    display: 'flex', 
                                    justifyContent: 'right', 
                                    alignItems: 'center',}}>
                                    <Typography sx={{fontSize: {xs: 14, md: 12}}}>
                                        24H after order placement
                                    </Typography>
                                </Box>

                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center',
                                mt: 2, 
                                mb: 2,
                                width: '95%'}}>

                                <Box sx={{
                                    display: 'flex', 
                                    width: '50%', 
                                    }}>
                                    <Typography 
                                        textAlign={'left'} 
                                        sx={{
                                        fontSize: 18, 
                                        fontFamily: 'Quicksand',
                                        fontWeight: 600}}>
                                        PRICE:
                                    </Typography>
                                </Box>

                                {/* <Box sx={{
                                    display: 'flex', 
                                    justifyContent: 'end', 
                                    width: {xs: '30%'}}}>
                                    <Typography textAlign={'end'} sx={{fontSize: 18, fontWeight: 500}}>
                                        {totalPrice === '' ? '' : '$'}
                                        {'$'}
                                    </Typography>
                                </Box> */}
                                <Box 
                                sx={{
                                    display: 'flex', 
                                    justifyContent: 'flex-end', 
                                    width: {xs: '50%'}}}>
                                    <Typography textAlign={'right'} sx={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: 600}}>
                                        {/* {totalPrice === '' ? Math.round(100 * totalPrice)/100 : Math.round(100 * totalPrice)/100} */}
                                        $ {product.variations[0].price}
                                    </Typography>
                                </Box>

                            </Box>
                            
                            <Box sx={{
                                display: 'flex', 
                                width: '95%', 
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2, mb: 2,
                                }}>

                                    {
                                    !itemsInCart ? 
                                    // TRUE Condition
                                        <ColorButton 
                                            // disabled={ variation === '' ? true : false}
                                            variant='contained' 
                                            disableElevation
                                            sx={{
                                                width: '98%',
                                                mt: {xs: '2%', md: '2%'},
                                                mb: {xs: '2%', md: '2%'},
                                            }}
                                            onClick={() => {
                                                addProduct(
                                                    {
                                                        product: product, 
                                                        Price: product.variations[0].price, 
                                                        Var: product.variations[0], 
                                                        subid: product.variations[0].subId,
                                                        minOrder: product.minOrder,
                                                    }
                                                )
                                            }}
                                            >
                                                ADD TO CART
                                        </ColorButton>
                                    : 
                                    // FALSE Condition
                                    (
                                        itemsInCart && cartItems.find(item => item.product.id === subId)) 
                                        ? 
                                        <ColorButton
                                            variant='contained' 
                                            disableElevation
                                            sx={{
                                                width: '98%',
                                                mt: {xs: '2%', md: '2%'},
                                                mb: {xs: '2%', md: '2%'},
                                            }}
                                            onClick={() => {navigate('/cart')}}>
                                            Review Order
                                        </ColorButton> 
                                        : 
                                        <ColorButton
                                            variant='contained' 
                                            disableElevation
                                            sx={{
                                                width: '98%',
                                                mt: {xs: '2%', md: '2%'},
                                                mb: {xs: '2%', md: '2%'},
                                            }}
                                            onClick={() => {
                                                addProduct(
                                                    {
                                                        product: product, 
                                                        Price: product.variations[0].price, 
                                                        Var: product.variations[0], 
                                                        subid: product.variations[0].subId,
                                                        minOrder: product.minOrder,
                                                    }
                                                )
                                            }}>
                                            ADD TO CART
                                        </ColorButton>
                                    }

                            </Box>

                        </Box>
                    }
                </Container>
            </Layout>
        </div>
    )

}