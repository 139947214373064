import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Button, Box, Typography, Toolbar, AppBar, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu'
import { grey } from "@mui/material/colors";
// import InstagramIcon from '@mui/icons-material/Instagram';
import Cart from "./cart";
// import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';


const Header = () => {

    const [anchorElNav, setAnchorElNav] = useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    // NOTE: Private dining controls
    // const [PDanchorEl, setPDAnchorEl] = useState(null)
    // const PDopen = Boolean(PDanchorEl)

    // const handlePDClick = (event) => {
    //   setPDAnchorEl(event.currentTarget);
    // }

    // const handlePDClose = () => {
    //   setPDAnchorEl(null);
    // }

    // NOTE: Catering controls
    // const [CateringanchorEl, setCateringAnchorEl] = useState(null)
    // const Cateringopen = Boolean(CateringanchorEl)

    // const handleCateringClick = (event) => {
    //     setCateringAnchorEl(event.currentTarget);
    // }

    // const handleCateringClose = () => {
    //     setCateringAnchorEl(null);
    // }

    return(
        <Box sx={{display: 'flex', }}>
            <AppBar position='static' color='primary' elevation={0}>

                {/* Logo Toolbar */}
                <Toolbar 
                    component={Box} 
                    sx={{  
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        background: 'white',
                        }}>
                        <Button component={Link} to="/" 
                            // disableRipple 
                            sx={{
                            '&.Mui-focusVisible': {bgcolor: grey[50]},
                            '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                            }}>
                            <img 
                                // src={require("../assets/trans.PNG")} 
                                src={require("../assets/trans.png")} 
                                alt="The pantry catering and bakery logo"
                                width={550}
                                />
                        </Button>
                </Toolbar>

                <Toolbar 
                    component={Box} 
                    sx={{  
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', 
                        borderBottom: 1, 
                        borderTop: 1,
                        borderColor: 'black',
                        // mb: '2%',
                        }}>

                        {/* For Small and above */}
                        <Box sx={{
                            height: '100%',
                            width: '100%', 
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            display: { xs: 'none', sm: 'flex' },
                            }}>

                            <Button 
                                component={Link} 
                                to="/" 
                                sx={{ 
                                    color: grey[900],
                                    '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                    }}>
                                <Typography textAlign={'center'} sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                    HOME
                                </Typography>
                            </Button>

                            <Button 
                                component={Link} 
                                to="/shop" 
                                sx={{ 
                                    color: grey[900],
                                    '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                    }}>
                                <Typography textAlign={'center'} sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                    CATERING
                                </Typography>
                            </Button>

                            {/* <Button 
                                onClick={handlePDClick} 
                                sx={{ 
                                    color: grey[900],
                                    fontFamily: 'inherit',
                                    '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                    }}>
                                <Typography textAlign={'center'} sx={{fontSize: 14, fontWeight: 500}}>
                                    EVENTS
                                </Typography>
                            </Button>

                            <Menu
                                anchorEl={PDanchorEl}
                                open={PDopen}
                                onClose={handlePDClose}
                                sx={{fontSize: 14, fontFamily: 'inherit'}}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                  }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',        
                                }}>

                                <MenuItem component={Link} to="/corporate" onClick={handlePDClose}>
                                    <Typography sx={{fontSize: 16, fontWeight: 500, fontFamily: 'inherit'}}>
                                        CORPORATE EVENTS
                                    </Typography>
                                </MenuItem>
                            </Menu> */}

                            <Button component={Link} to="/" sx={{ 
                                color: grey[900],
                                '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                }} disabled>
                                <Typography textAlign={'center'} sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                    PRESS
                                </Typography>
                            </Button>

                            <Button component={Link} to="/contact" sx={{ 
                                color: grey[900],
                                '&.MuiButton-root:hover': {bgcolor: 'transparent'}  
                                }}>
                                <Typography textAlign={'center'} sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                    CONTACT
                                </Typography>
                            </Button>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Button 
                                    // component={Link}
                                    sx={{  
                                        color: grey[900],
                                        display: 'flex',
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        '&.MuiButton-root:hover': {bgcolor: 'transparent'},
                                        mb: '2%',}}>
                                    <Cart />
                                </Button>
                            </Box>

                        </Box>

                        <Box sx={{ 
                            width: '100%',
                            display: { xs: 'flex', sm: 'none' },
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            }}>

                            <Button component={Link} to="/" 
                                sx={{ 
                                    color: grey[900],
                                    '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                    }}>
                                <Typography sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                    HOME
                                </Typography>
                            </Button>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Button 
                                    // component={Link}
                                    sx={{  
                                        color: grey[900],
                                        display: 'flex',
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        '&.MuiButton-root:hover': {bgcolor: 'transparent'},
                                        mb: '2%',}}>
                                    <Cart />
                                </Button>
                            </Box>

                            {/* <Button 
                                component={Link} 
                                to="/reservation" 
                                sx={{ 
                                    color: grey[900],
                                    '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                    }}>
                                <Typography textAlign={'center'} sx={{fontSize: 14, fontWeight: 400}}>
                                    PRIVATE EVENTS
                                </Typography>
                            </Button> */}

                            {/* <Button 
                                onClick={handlePDClick} 
                                sx={{ 
                                    color: grey[900],
                                    fontFamily: 'inherit',
                                    '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                    }}>
                                <Typography textAlign={'center'} sx={{fontSize: 14}}>
                                    EVENTS
                                </Typography>
                            </Button>

                            <Menu
                                anchorEl={PDanchorEl}
                                open={PDopen}
                                onClose={handlePDClose}
                                sx={{fontSize: 14, fontFamily: 'inherit'}}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                  }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',        
                                }}>

                                <MenuItem component={Link} to="/corporate" onClick={handlePDClose}>
                                    <Typography sx={{fontSize: 16, fontWeight: 500, fontFamily: 'inherit'}}>
                                        CORPORATE EVENTS
                                    </Typography>
                                </MenuItem>

                            </Menu> */}

                            <IconButton size='large' onClick={handleOpenNavMenu}>
                                <MenuIcon color={ grey['900'] }/>
                            </IconButton>
                            <Menu 
                                anchorEl={anchorElNav} 
                                keepMounted
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                  }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                >


                                <MenuItem>
                                    <Button component={Link} to="/shop" 
                                        sx={{ 
                                            color: grey[900],
                                            '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                            }}>
                                        <Typography sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                            CATERING
                                        </Typography>
                                    </Button>
                                </MenuItem>

                                <MenuItem>
                                    <Button component={Link} to="/" sx={{ 
                                        color: grey[900],
                                        '&.MuiButton-root:hover': {bgcolor: 'transparent'} 
                                        }} disabled>
                                        <Typography sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                            PRESS
                                        </Typography>
                                    </Button>
                                </MenuItem>

                                <MenuItem>
                                    <Button component={Link} to="/contact" sx={{ 
                                        color: grey[900],
                                        '&.MuiButton-root:hover': {bgcolor: 'transparent'}  
                                        }}>
                                        <Typography sx={{fontSize: 14, fontFamily: 'Quicksand', fontWeight: 700}}>
                                            CONTACT
                                        </Typography>
                                    </Button>
                                </MenuItem>

                            </Menu>

                        </Box>

                </Toolbar>

            </AppBar>
        </Box>

    )
}

export default Header