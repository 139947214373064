import { Typography, Box, IconButton } from "@mui/material";
import React from "react";
import { Link } from 'react-router-dom'
import InstagramIcon from '@mui/icons-material/Instagram';
import { grey } from "@mui/material/colors";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import XIcon from '@mui/icons-material/X';


const Footer = () => {

    const year = new Date().getFullYear()

    return(

        <Box sx={{
            display: 'flex', 
            flexDirection: {xs: 'column', md: 'row'},
            background: '#F8F6F1',
            // alignItems: 'start',
            width: '100%',
            height: '15vh',
            borderTop: 0.5, 
            // borderBottom: 0.5,
            borderColor: 'black',
            mt: '2%',
            }}>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center',
                justifyContent: {xs: 'center', md: 'left'},
                width: '100%',
                mt: {xs: '4%', md: '2%'},
                mb: '4%',
                }}>

                <Typography sx={{
                    fontSize: 16, 
                    fontWeight: 500,
                    color: grey[900],
                    ml: {md: '4%'}, 
                    }}>
                    The Pantry Bakery and Catering &copy;  2023 - {year}
                </Typography>

            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '100%',
                mt: {md: '2%'}, 
                mb: '4%', 
                }} >

                <Typography sx={{
                    fontSize: 16, 
                    fontWeight: 500,
                    color: grey[900],
                    ml: {md: '4%'}, 
                    }}>
                    Follow us on
                </Typography>

                <IconButton 
                    disableRipple={false} 
                    component={Link} 
                    to={'http://www.instagram.com/thepantry.catering'}>
                    <InstagramIcon />
                </IconButton>

                <IconButton 
                    disableRipple={false} 
                    component={Link} 
                    to={'http://www.instagram.com/thepantry.catering'}>
                    <FacebookIcon />
                </IconButton>

                <IconButton 
                    disableRipple={false} 
                    component={Link} 
                    to={'http://www.instagram.com/thepantry.catering'}>
                    <XIcon />
                </IconButton>

            </Box>

            {/* <Box sx={{
                width: '30%', 
                mt: '2%', 
                }} >
                <Typography textAlign={'right'} 
                sx={{
                    fontSize: 16,
                    color: grey[900], 
                    mr: '4%', 
                    mt: '2%', 
                    mb: '2%'}}>
                    All rights reserved.
                </Typography>
            </Box> */}

        </Box>
    )
}

export default Footer