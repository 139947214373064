import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React  from "react";
import { Link } from "react-router-dom";
// import { grey, lightGreen, yellow } from "@mui/material/colors";
// import { styled } from '@mui/material/styles';
import CheckOut from "../stripe/checkoutStripe";
import { useStripe, } from '@stripe/react-stripe-js';
import ColorButton from './coloredButton';

const Total = (props) => {

    const {cartItems, itemCount, total, clearCart} = props

    const stripe = useStripe()

    function minOrder(cartItems, total) {

        const smt = cartItems.length

        let bims = 0

        let condition

        let n = 0

        // console.log(cartItems)

        var a = [51, 61, 71, 81]    // indices of bibimpabs

        cartItems.forEach(item => {

            if ( a.indexOf(item.subid) !== -1) {
                console.log('Cond 2: Bibim is in order.')
                bims = bims + 1
            }

            if (item.minOrder <= item.quantity) {
                // console.log('min is :', item.minOrder, 'quantity is:', item.quantity)
                n = n + 1

                console.log('Items with mo > Q: ', n)
            }

        });

        if (bims >= 1) {

            if (n === smt) {
                if (total >= 100) {
                    condition = false
                } else {
                    condition = true
                }
            } else {
                condition = true
            }

        } else {

            if (n === smt) {
                console.log('Condition (1) is satisfied.')
                condition = false
            } else {
                condition = true
            }

        }

        return condition

    }

    return(
        <Card variant='outlined' sx={{}}>

            <CardHeader 
                disableTypography
                title={<Typography textAlign={'center'} sx={{fontSize: 30, fontFamily:'Blaster', fontWeight: 700}}>Summary</Typography>} 
                // titleTypographyProps={{variant: 'h5', textAlign: 'center', fontWeight: 'bold', }}
                />

            <CardContent>

                <Grid container direction={{xs: 'column', sm: 'row'}} spacing={{xs: 2}}>

                    <Grid item xs={12} sm={6}>

                        <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',}}>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: 16, fontFamily: 'Quicksand', fontWeight: 500}}>
                                    Items:
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: 16, fontFamily: 'Quicksand', fontWeight: 600}}>
                                    {itemCount}
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: 16, fontFamily: 'Quicksand', fontWeight: 500}}>
                                    Total:
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography textAlign={'center'} sx={{fontSize: 16, fontFamily: 'Quicksand', fontWeight: 600}}>
                                    ${Math.round(100 * total) / 100}
                                </Typography>
                            </Box>

                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        { itemCount > 0 && 
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '100%', }}>
                                <ColorButton
                                    variant='contained' 
                                    disableElevation
                                    // disabled={total > 150 ? false : true}
                                    disabled={minOrder(cartItems, total)}
                                    onClick={(e) => {
                                        CheckOut(cartItems)
                                        .then( res => {
                                            const session_Id = res.id
                                            stripe.redirectToCheckout({sessionId: session_Id})
                                        })

                                    }} 
                                    sx={{width: {xs: '45%', sm: 'auto'}, maxwidth: '49%', pl: 2, pr: 2}}>
                                    CHECKOUT
                                </ColorButton>

                                <ColorButton 
                                variant='contained' 
                                disableElevation
                                onClick={() => clearCart()} sx={{width: {xs: '45%', sm: 'auto'}, maxwidth: '49%', pl: 2, pr: 2}}>
                                    CLEAR CART
                                </ColorButton>
                            </Box>
                        }
                        { itemCount === 0 &&
                            <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                <ColorButton 
                                    variant='contained' 
                                    disableElevation
                                    component={Link} to='/shop' sx={{width: '80%'}}>
                                    GO TO SHOP
                                </ColorButton>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Total