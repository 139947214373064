const SHOP_DATA = [
    // {
    //     id: 1,
    //     title: 'BIBIMBOP',  
    //     subTitle: ' (Korean Rice Bowl)',
    //     description: 'Bibimbap (비빔밥) is a delicious rice bowl topped with vegetables, choice of protein, an egg, and gochujang (red chili pepper paste). The term bibim means "mixing" and bap is cooked rice. It is a filling meal that’s always a good idea.\n Our bowl is prepared with only organic vegetable and rice. You can add organic egg or variety of protein to your bowl as well.',
    //     allergens: ['Milk', 'Egg'],
    //     ingrediants: 'NN',
    //     variations: [
    //         {type: 'Original', price: 13.99, subId: 101, image: ['https://www.google.com/url?sa=i&url=https%3A%2F%2Fiheartumami.com%2Fvegetarian-bibimbap-recipe%2F&psig=AOvVaw3p_kh_2AK0ZtY16pJLrqON&ust=1711505466458000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCPjct_nskIUDFQAAAAAdAAAAABAE']},
    //         {type: 'Vegan (Tofu)', price: 16.99, subId: 102, image: [require('../assets/tofu.png')]},
    //         {type: 'Chicken', price: 17.99, subId: 103, image: [require('../assets/Q31.jpg')]},
    //         {type: 'Beef', price: 17.99, subId: 104, image: [require('../assets/Q31.jpg')]},
    //         {type: 'Spciy Pork', price: 17.99, subId: 105, image: [require('../assets/Q31.jpg')]}
    //     ],
    //     instructions: 'NN',
    //     imageUrl: [
    //         require('../assets/BibimMeat.jpg'),
    //     ],
    // }, {
    //     id: 21,
    //     title: 'GRAZING BOX',  
    //     subTitle: 'Mini Size',
    //     allergens: ['Milk', 'Egg'],
    //     ingrediants: 'NN',
    //     instructions: 'NN',
    //     variations: [
    //         {type: 'Mini', price: 18.99, subId: 211, image: [require('../assets/GB_mini.jpg')]},
    //     ],
    //     imageUrl: [
    //         require('../assets/GBM2.jpg'),
    //     ],
    // }
    {
        id: 1,
        name: 'Grazing_box_mini',
        title: 'GRAZING BOX',  
        subTitle: 'Mini Cup',
        description: 'Perfect for brand, corporate and press events, wedding canapes, low-key laid back events and so much more! (Minimum order of 20).',
        allergens: ['Milk', 'Nuts'],
        ingrediants: 'A variety of seasonal berries, dried fruits, an assortment of cured meats, and savory snacks.',
        minOrder: 20,
        instructions: 'NN',
        variations: [
            {
                type: 'Mini Cup', 
                price: 12.00, 
                subId: 11, 
                image: [
                    require('../assets/mini1.jpg'),
                    require('../assets/mini2.jpg')
                ]
            },
        ],
        imageUrl: [
            require(
                '../assets/miniBanner.jpg'
            ),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/boxes%2FminiBanner.jpg?alt=media&token=abca3e85-de65-455c-9bb7-da841dfe4433'
        ],
    },
    {
        id: 2,
        name: 'Grazing_box_small',
        title: 'GRAZING BOX',  
        subTitle: 'Small Size',
        description: 'This personal-sized charcuterie box offers a delightful selection of seasonal berries, dried fruits, an assortment of cured meats, and savory snacks. (Minimum order of 10).',
        allergens: ['Milk', 'Nuts'],
        ingrediants: 'A variety of seasonal berries, dried fruits, an assortment of cured meats, and savory snacks.',
        minOrder: 10,
        instructions: 'NN',
        variations: [
            {
                type: 'Small Size', 
                price: 18.00, 
                subId: 21, 
                image: [
                    require('../assets/small1.jpg'),
                    require('../assets/small2.jpg'),
                    require('../assets/small3.jpg'),
                ]},
        ],
        imageUrl: [
            require(
                '../assets/smallBanner.jpg'
                
            ),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/boxes%2FsmallBanner.jpg?alt=media&token=163f9751-f503-436b-983a-534c9ba11dbe'
        ],
    },
    {
        id: 3,
        name: 'Grazing_box_medium',
        title: 'GRAZING BOX',  
        subTitle: 'Medium Size',
        description: 'Perfect for intimate gatherings, corporate meetings, date nights, and casual get-togethers! This larger charcuterie box feeds 2-3 people and offers a delightful selection of seasonal fruits, dried fruits, an assortment of cured meats, jam or honey and savory snacks.',
        allergens: ['Milk', 'Nuts'],
        ingrediants: 'A variety of seasonal fruits, an assortment of cured meats, dried fruits, and savory items.',
        minOrder: 1,
        instructions: 'NN',
        variations: [
            {
                type: 'Medium Size', 
                price: 40.00, 
                subId: 31, 
                image: [
                    require('../assets/medium1.jpg'),
                    require('../assets/medium2.jpg'),
                    require('../assets/medium3.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/medBanner.jpg'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/boxes%2FmedBanner.jpg?alt=media&token=d2458b47-601b-422b-be08-b17a75b9d9ab',
        ],
    },
    {
        id: 4,
        name: 'Grazing_box_large',
        title: 'GRAZING BOX',  
        subTitle: 'Large Size',
        description: 'Perfect for sharing the gift of cheese! Jam-packed with delicious produce perfect to curb your cheesy feeling. Generously feeds up to 4 and up to 6 appetiser portions.',
        allergens: ['Milk', 'Nuts'],
        ingrediants: 'A variety of seasonal berries, dried fruits, an assortment of cured meats, and savory snacks.',
        minOrder: 1,
        instructions: 'NN',
        variations: [
            {
                type: 'Large Size', 
                price: 65, 
                subId: 41, 
                image: [
                    require('../assets/large1.jpg'),
                    require('../assets/large2.jpg'),
                    require('../assets/large3.jpg'),
                ]},
        ],
        imageUrl: [
            require(
                '../assets/largeBanner.jpg'
                
            ),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/boxes%2FlargeBanner.jpg?alt=media&token=27f2a375-45f7-4d2c-bd15-3ee8178073ea'
        ],
    },
    {
        id: 5,
        name: 'Bibimbop_original',
        title: 'BIBIMBOP',  
        subTitle: 'Original',
        description: 'Bibimbap (비빔밥) is a delicious rice bowl topped with variety of vegetables, and gochujang (red chili pepper paste). The term bibim means "mixing" and bap is cooked rice. It is a filling meal that/s always a good idea. Our bowl is prepared with only organic vegetable and rice. You can add organic egg to your bowl as well.',
        allergens: ['Milk', 'Egg', 'Soy Sauce'],
        ingrediants: 'Sauteed Daikon, Carrots, Mushrooms, Zucchini, blanched and seasoned spinach, bean sprouts, burdock root, fresh red cabbage and radish sprouts.',
        minOrder: 8,
        instructions: 'NN',
        variations: [
            {
                type: 'Original', 
                price: 14.00, 
                subId: 51, 
                image: [
                    require('../assets/borigin1.jpg'),
                    require('../assets/borigin2.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/boriginBanner.jpg'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/bibimbap%2FboriginBanner.jpg?alt=media&token=1316e560-6fa7-4528-9213-f18f1423c307'
        ],
    },
    {
        id: 6,
        name: 'Bibimbop_meat',
        title: 'BIBIMBOP',  
        subTitle: 'Meat',
        description: 'Bibimbap (비빔밥) is a delicious rice bowl topped with variety of vegetables, freshly cooked marinated beef, and gochujang (red chili pepper paste). The term bibim means "mixing" and bap is cooked rice. It is a filling meal that/s always a good idea. Our bowl is prepared with only organic vegetable and rice. You can add organic egg to your bowl as well.',
        allergens: ['Milk', 'Egg', 'Soy Sauce'],
        ingrediants: 'Cooked marinated beef, sauteed daikon, carrots, mushrooms, zucchini, blanched and seasoned spinach, bean sprouts, burdock root, fresh red cabbage and radish sprouts.',
        minOrder: 1,
        instructions: 'NN',
        variations: [
            {
                type: 'Meat', 
                price: 18.00, 
                subId: 61, 
                image: [
                    require('../assets/bmeat1.jpg'),
                    require('../assets/bmeat2.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/BibimMeat.jpg'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/bibimbap%2FBibimMeat.jpg?alt=media&token=11cf68e0-a512-42e6-97ad-44499f8f336f'
        ],
    },
    {
        id: 7,
        name: 'Bibimbop_tofu',
        title: 'BIBIMBOP',  
        subTitle: 'Tofu',
        description: 'Bibimbap (비빔밥) is a delicious rice bowl topped with vegetables, marinated oven baked tofu, and gochujang (red chili pepper paste). The term bibim means "mixing" and bap is cooked rice. It is a filling meal that is always a good idea. Our bowl is prepared with only organic vegetable and rice. You can add organic egg to your bowl as well.',
        allergens: ['Milk', 'Egg', 'Soy Sauce'],
        ingrediants: 'Tofu, sauteed daikon, carrots, mushrooms, zucchini, blanched and seasoned spinach, bean sprouts, burdock root, fresh red cabbage and radish sprouts.',
        minOrder: 1,
        instructions: 'NN',
        variations: [
            {
                type: 'Tofu', 
                price: 17.00, 
                subId: 71, 
                image: [
                    require('../assets/tofuBanner.jpg'),
                    require('../assets/tofu2.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/tofu.png'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/bibimbap%2Ftofu.png?alt=media&token=0630d7db-81ba-483d-94b8-257267f48110'
        ],
    },
    {
        id: 8,
        name: 'Bibimbop_spicy pork',
        title: 'BIBIMBOP',  
        subTitle: 'Spicy Pork',
        description: 'Bibimbap (비빔밥) is a delicious rice bowl topped with vegetables, gochujang marinated pork, and gochujang (red chili pepper paste). The term bibim means "mixing" and bap is cooked rice. It is a filling meal that is always a good idea. Our bowl is prepared with only organic vegetable and rice. You can add organic egg to your bowl as well.',
        allergens: ['Milk', 'Egg', 'Soy Sauce'],
        ingrediants: 'Marinated pork, sauteed daikon, carrots, mushrooms, zucchini, blanched and seasoned spinach, bean sprouts, burdock root, fresh red cabbage and radish sprouts.',
        minOrder: 1,
        instructions: 'NN',
        variations: [
            {
                type: 'Spicy Pork', 
                price: 18.00, 
                subId: 81, 
                image: [
                    require('../assets/spicyBanner.jpg'),
                    require('../assets/bspicy2.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/spicy2.png'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/bibimbap%2Fspicy2.png?alt=media&token=ed505120-db3d-4ab2-8ac3-46023cf7e09c'
        ],
    },
    {
        id: 9,
        name: 'Salt_bread',
        title: 'SALT BREAD',  
        subTitle: '(소 금 빵)',
        description: 'Salt bread is a special type of bread in Korea, primarily made with salt and butter. It also includes flour, sugar, milk and yeast. While it contains a bit more salt compare to other breads, it is carefully balanced to avoid being too salty. The key characteristics of this bread its crispy bottom and soft, moist interior. The proper baking process gives it a golden-brown crust while maintaining a tender, buttery structure inside. We offer a delightful dozen of bread in every box (order).',
        allergens: ['Milk', 'Egg'],
        ingrediants: 'Salt, butter, flour',
        minOrder: 2,
        instructions: 'NN',
        variations: [
            {
                type: 'Original', 
                price: 48.00, 
                subId: 91, 
                image: [
                    require('../assets/salt1.jpg'),
                    require('../assets/salt2.jpg'),
                    require('../assets/salt3.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/DSC00449.jpg'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/saltbread%2FDSC00449.jpg?alt=media&token=44763cf9-9c19-4306-b4e6-bff43303c9d4',
        ],
    },
    {
        id: 10,
        name: 'Quiche_mushroom_and_spinach',
        title: 'Quiche',  
        subTitle: 'Spinach and Mushroom',
        description: 'Perfect for brunches, family gatherings, corporate meetings, and special occasions! Our ham and mushroom quiche offers a delectable blend of savory ham, earthy mushrooms, and rich cheese, all baked to perfection in a golden, flaky crust. This quiche serves 8-10 people and is sure to be a crowd-pleaser at any event.',
        allergens: ['Milk', 'Egg'],
        ingrediants: 'Ham, spinach, egg, cream, flour',
        minOrder: 1,
        instructions: 'NN',
        variations: [
            {
                type: 'Spinach and Mushroom', 
                price: 68.00, 
                subId: 101, 
                image: [
                    require('../assets/Q1.jpg'),
                    require('../assets/Q31.jpg'),
                    require('../assets/Q2.jpg'),
                ]},
        ],
        imageUrl: [
            require('../assets/Q1.jpg'),
            'https://firebasestorage.googleapis.com/v0/b/webstore-35f8c.appspot.com/o/quiche%2FQ1.jpg?alt=media&token=d6f3b4ff-4a48-4335-a820-131ff61138f4'
        ],
    }
]

export default SHOP_DATA;